<template>
  <div>
    <topNav></topNav>
    <div class="big-box">
      <div class="left">
        <img src="../../assets/images/T12422-(1).png" alt="" />
      </div>
      <div class="right">
        <p class="title">游戏交易 收游帮您</p>
        <div>
          <img src="../../assets/images/scan-icon.png" alt="" />
          <span>扫码下载收游帮APP</span>
        </div>
        <qrcode-vue
          :value="qrCode"
          size:600
          class="qrcode"
          style="width: 278px; height: 278px; border: 10px solid #ffffff; border-radius: 10px"
        ></qrcode-vue>
        <p>苹果安卓均可扫码下载</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import topNav from '@/views/Layout/components/top-nav.vue'
import { ref } from 'vue'
import QrcodeVue from 'qrcode.vue'
import { version } from '@/api/home'
const qrCode = ref('https://www.sybaopei.com/h5')
console.log(window)
</script>
<style lang="less" scoped>
.big-box {
  width: 100%;
  height: 100vh;
  background: url('../../assets/images/download-bg.png') no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 200px;
  .left {
  }
  .right {
    text-align: center;
    .title {
      font-size: 100px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 90px;
      span {
        font-size: 42px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .qrcode {
    }
    p:nth-child(4) {
      font-size: 24px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      margin-top: 22px;
    }
  }
}
</style>
